import type { FlatNamespace, KeyPrefix } from "i18next";
import {
	type FallbackNs,
	type UseTranslationOptions,
	useTranslation,
} from "react-i18next";

export function useT<
	Ns extends
		| FlatNamespace
		| readonly [FlatNamespace?, ...FlatNamespace[]]
		| undefined = undefined,
	KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(ns?: Ns, options?: UseTranslationOptions<KPrefix>) {
	return useTranslation(ns, options).t;
}
