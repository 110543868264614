import { Copy, CopyCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "react-aria-components";

let command = "npx create-remix@latest --template edgefirst-dev/starter";

export function Command() {
	let [copied, setCopied] = useState(false);

	useEffect(() => {
		if (!copied) return;
		let id = setTimeout(setCopied, 5000, false);
		return () => clearTimeout(id);
	}, [copied]);

	return (
		<Button
			className="hidden items-center gap-2 rounded-md bg-neutral-100 px-3 py-1.5 font-light font-mono text-neutral-950 text-xs/none shadow focus:outline focus:outline-2 focus:outline-accent-400 focus:outline-offset-2 sm:h-stack dark:bg-neutral-900 dark:text-neutral-50"
			onPress={() => {
				navigator.clipboard.writeText(command).then(() => setCopied(true));
			}}
		>
			<span>{command}</span>
			{copied ? (
				<CopyCheck aria-hidden className="size-4" />
			) : (
				<Copy aria-hidden className="size-4" />
			)}
		</Button>
	);
}
