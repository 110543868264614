import { Trans } from "react-i18next";
import { useT } from "~/hooks/use-t";
import { Command } from "./components/command";
import { GetStarted } from "./components/get-started";

export default function Index() {
	let t = useT("translation");

	return (
		<main className="center v-stack min-h-dvh gap-8 px-4 sm:px-0">
			<header className="v-stack gap-4 text-center">
				<h1 className="text-pretty bg-gradient-to-b from-accent-400 to-accent-950 bg-clip-text font-bold text-4xl/tight text-transparent tracking-tighter sm:text-4xl/snug md:text-5xl/snug lg:text-6xl/snug dark:from-accent-950 dark:to-accent-300">
					{t("main.header.title.first")}
					<br />
					{t("main.header.title.second")}
				</h1>

				<p className="mx-auto max-w-[700px] text-pretty text-lg text-neutral-500 sm:font-light sm:text-base md:text-xl dark:text-neutral-400">
					<Trans
						t={t}
						i18nKey="main.header.description"
						components={{
							b: <span className="text-accent-500" />,
							br: <br className="hidden sm:block" />,
						}}
					/>
				</p>
			</header>

			<div className="v-stack gap-4 sm:h-stack">
				<GetStarted />
				<Command />
			</div>
		</main>
	);
}
