import { ArrowRight } from "lucide-react";
import { useT } from "~/hooks/use-t";

export function GetStarted() {
	let t = useT("translation");
	return (
		<a
			className="button relative rounded-md bg-accent-500 px-5 py-2.5 font-light text-accent-50 text-base/none shadow-md focus:outline focus:outline-2 focus:outline-accent-400 focus:outline-offset-2 dark:bg-accent-500 dark:text-accent-950 dark:shadow-none"
			href="https://github.com/edgefirst-dev/stack"
		>
			<div className="fancy absolute inset-0" />
			<span className="center relative gap-2">
				{t("main.header.cta.getStarted")}
				<ArrowRight aria-hidden className="size-5" />
			</span>
		</a>
	);
}
